<template>
    <v-sheet>
        <v-card-title
                :id="name"
                primary-title
                class="display-2 justify-center font-weight-bold grey--text text--darken-3 mb-3"
        >
            {{name}}
        </v-card-title>
        <v-container>
            <v-row>
                <v-col
                        v-for="(feature, i) in value"
                        :key="i"
                        cols="12"
                        sm="6"
                        md="3"
                >
                    <v-skeleton-loader
                            type="image"
                            transition="scale-transition"
                            height="200"
                            :loading="skeleton"
                    >
                    <v-hover
                            close-delay="500"
                    >
                        <v-card
                                flat
                                tile
                                slot-scope="{ hover }"
                                color="rgba(0, 0, 0, 0.2)"
                                :elevation="hover ? 12 : 0"
                        >
                            <v-img
                                    :src="feature.acf.athlete_image"
                                   @click="goTo('Athlete',feature.ID, false)"
                                   style="cursor: pointer"
                                   aspect-ratio="1"
                            >
                                <v-expand-transition
                                        class="hidden-md-and-down"
                                >
                                    <div
                                            v-if="hover"
                                            class ="d-flex transition-fast-in-fast-out red darken-2 v-card--reveal display-3 white--text"
                                            style="height: 50%; cursor: pointer"
                                    >
                                        {{feature.acf.athlete_firstName}}
                                    </div>
                                </v-expand-transition>
                                <div
                                        v-if="staticName"
                                        class=" hidden-md-and-up d-flex red darken-2  v-card--reveal--static display-3 white--text"
                                        style="height: 30%; cursor: pointer"
                                >
                                    {{feature.acf.athlete_firstName}}
                                </div>
                            </v-img>
                        </v-card>
                    </v-hover>
                    </v-skeleton-loader>
                </v-col>
            </v-row>
        </v-container>
    </v-sheet>
</template>

<script>
    export default {
        name: "Team",
        props:{
            value: {
                type: Array,
                default: () => ([])
            },
            name: {
                type: String,
                default:'Team'
            },
            skeleton: {
                type: Boolean,
                default: false
            }
        },
        computed: {
          staticName () {
              let value
              value = this.$vuetify.breakpoint. mdAndDown
              return value
          }
        },
        methods:{
            goTo(name, id, value) {
                this.$router.push({ name: 'Athlete', params: { id } })
                this.$store.dispatch('app/setLink', value)
            }
        }
    }
</script>

<style scoped>

</style>
