<template>
    <section id="talk">
        <base-card>
            <v-card-text
                    class="text-center justify-center text-lg-h6 text-sm-subtitle-1 font-weight-light mx-auto mb-5"
            >
                <span>{{athleteCounter}}</span>
                <span>{{$t('teams.text')}}</span>
            </v-card-text>
                <team :value="y" :name="$t('teams.youth')" :skeleton="skeletons.youth" id="youth"/>
                <team :value="women" :name="$t('teams.women')" :skeleton="skeletons.women" id="women" />
                <team :value="men" :name="$t('teams.men')" :skeleton="skeletons.men" id="men"/>
                <team :value="proWomen" :name="$t('teams.proWomen')" :skeleton="skeletons.proWomen" id="prowomen"/>
                <team :value="proMen" :name="$t('teams.proMen')" :skeleton="skeletons.proMen" id="promen"/>
        </base-card>
    </section>
</template>

<script>
    import Team from "@/components/teams/Team"
    export default {
        components: {Team},
        props: [
            'y'
        ],
        data: () => ({
            youth: [],
            women: [],
            men: [],
            proWomen: [],
            proMen:[],
            skeletons: {
                youth: true,
                women: true,
                men: true,
                proWomen: true,
                proMen: true,
            },
            athleteCounter: null
        }),
        mounted(){
            fetch('https://ktt01.de/wordpress/?rest_route=/athletes/count/post')
                .then((r) => r.json())
                .then((res) => this.athleteCounter = res.count)

            this.skeletons.youth = false
            fetch('https://ktt01.de/wordpress/?rest_route=/athletes/female/league/post')
                .then((r) => r.json())
                .then((res) => {
                    this.women = res
                    this.skeletons.women = false
                })
            fetch('https://ktt01.de/wordpress/?rest_route=/athletes/male/league/post')
                .then((r) => r.json())
                .then((res) => {
                    this.men = res
                    this.skeletons.men = false
                })
            fetch('https://ktt01.de/wordpress/?rest_route=/athletes/female/pro/post')
                .then((r) => r.json())
                .then((res) => {
                    this.proWomen = res
                    this.skeletons.proWomen = false
                })
            fetch('https://ktt01.de/wordpress/?rest_route=/athletes/male/pro/post')
                .then((r) => r.json())
                .then((res) => {
                    this.proMen = res
                    this.skeletons.proMen = false
                })
        }
    }
</script>

<style>
    .v-card--reveal {
        align-items: center;
        bottom: 0;
        justify-content: center;
        opacity: .5;
        position: absolute;
        width: 100%;
    }

    .v-card--reveal--static {
        align-items: center;
        bottom: 0;
        justify-content: center;
        opacity: .7;
        position: absolute;
        width: 100%;
    }
</style>
